<template>
  <step v-bind="$props">
    <template v-slot:name>First initialization</template>
    <template v-slot:content>
      Dernière chance pour modifier la configuration. Validez bien les données dans le datastore avant de lancer.
      Cette étape activera la config (is_active=True) et lancera un datainput d'initialisation.
      <v-btn color="primary" @click="$emit('launch')">Oui je le veux</v-btn>
    </template>
  </step>
</template>

<script>
import step from './step'
import { stepProps } from './stepProps'

export default {
  name: 'stepFirstInit',
  components: { step },
  mixins: [stepProps]
}
</script>

<style scoped>

</style>
