<template>
  <step v-bind="$props">
    <template v-slot:name>Project Creation</template>
    <template v-slot:description>Creation google cloud project</template>
  </step>
</template>

<script>
import { stepState } from './stepState'
import step from './step'
import { stepProps } from './stepProps'

export default {
  name: 'stepProjectCreation',
  components: { step },
  mixins: [stepProps]
}
</script>

<style scoped>

</style>
