<template>
  <step v-bind="$props">
    <template v-slot:name>Activate DBM API</template>
  </step>
</template>

<script>
import step from '../step'
import { stepProps } from '../stepProps'

export default {
  name: 'stepDbmActivateDbmApi',
  components: { step },
  mixins: [stepProps]
}
</script>

<style scoped>

</style>
