<template>
  <v-container class="keystone-tool-container">
    <component v-bind:is="toolComponent"></component>
  </v-container>
</template>

<script>
import projectConfig from '../components/KeystoneComponents/projectConfig/projectConfigDBM'
import splitAudience from '../components/KeystoneToolsComponents/appnexus/splitAudience'
import { keystoneTools } from '../../config/keystoneTools'
import baseViewMixin from '@/mixins/baseViewMixin'

export default {
  name: 'Keystone-tool.vue',
  props: ['dsp', 'tool'],
  components: {
    projectConfig, splitAudience
  },
  mixins: [baseViewMixin],
  computed: {
    toolComponent: function () {
      let tool = keystoneTools[this.dsp].find((element) => element.name === this.tool)
      return tool.component
    }
  },
  mounted () {
    this.onMounted()
  }
}
</script>

<style scoped>

</style>
