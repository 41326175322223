<template>
  <v-row row>
    <v-col xs="12" sm="8" offset-sm="2">
      <v-progress-circular
        indeterminate
        color="primary" v-if="loading"
      ></v-progress-circular>
      <v-card v-else-if="error">
        {{ error }}
      </v-card>
      <v-card v-else>
        <v-icon large style="color: green; vertical-align: middle" v-if="allStepCompleted" class="ml-2">done</v-icon>
        <v-btn color="primary" @click="$emit('start', currentStep)" :disabled="!canDoStep" v-else-if="allowToUpdateConfig">start</v-btn>
        <v-stepper
            :value="currentStep"
            @change="$emit('change', $event)"
            vertical
        >
          <slot></slot>
        </v-stepper>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'stepper',
  model: {
    prop: 'currentStep',
    event: 'change'
  },
  props: {
    loading: Boolean,
    error: String,
    allStepCompleted: Boolean,
    currentStep: Number,
    canDoStep: Boolean,
    allowToUpdateConfig: Boolean
  }
}
</script>

<style scoped>

</style>
