<template>
  <step v-bind="$props">
    <template v-slot:name>Create service Account api</template>
    <template v-slot:content>
      <v-progress-circular
        indeterminate
        color="primary" v-if="loading"
      ></v-progress-circular>
      <div v-else>
        <v-card elevation="0">
          <div>
            <p>
              créer un compte api :
              <a v-bind:href="saSettingsCreation.url_creation" class="text-wrap"
                 target="_blank" rel="noopener noreferrer">{{ saSettingsCreation.url_creation }}</a>
            </p>
            <ol>
              <li>Application Web</li>
              <li>
                nom: <b>{{ saSettingsCreation.account_name }}</b>
                <CopyClipboardComponent
                  :toCopy="saSettingsCreation.account_name"
                  :tooltipText="'Copy name to clipboard.'"
                  :btnSmall="true"
                  :elevation="false"
                  color="transparent"
                >
                </CopyClipboardComponent>
              </li>
              <li class="text-wrap">
                URI de redirection autorisés: <b>{{ saSettingsCreation.url_callback }}</b>
                <CopyClipboardComponent
                  :toCopy="saSettingsCreation.url_callback"
                  :tooltipText="'Copy url callback to clipboard.'"
                  :btnSmall="true"
                  :elevation="false"
                  color="transparent"
                >
                </CopyClipboardComponent>
              </li>
            </ol>

            <p>Entrer les infos du compte api</p>
            <v-col xs="12" sm="10" md="10">
              <v-text-field label="Client_id" small
                            :value="saCredentials.clientId"
                            @change="$emit('change', changeValue('clientId', $event))"
              ></v-text-field>
              <v-text-field label="Client_Secret" type="password"
                            :value="saCredentials.clientSecret"
                            @change="$emit('change', changeValue('clientSecret', $event))"
              ></v-text-field>
            </v-col>
          </div>
        </v-card>
        <v-btn color="primary" @click="$emit('save', saCredentials)" :disabled="!isValid">Save</v-btn>
      </div>
    </template>
  </step>
</template>

<script>
import step from '../step'
import { stepProps } from '../stepProps'
import CopyClipboardComponent from '@/components/Common/CopyClipboardComponent'

export default {
  name: 'stepDbmCreateServiceAccountApi',
  components: { CopyClipboardComponent, step },
  mixins: [stepProps],
  model: {
    prop: 'saCredentials',
    event: 'change'
  },
  props: {
    saCredentials: Object,
    saSettingsCreation: Object,
    loading: Boolean
  },
  methods: {
    changeValue: function (key, event) {
      this.saCredentials[key] = event
      return this.saCredentials
    }
  },
  computed: {
    isValid: function () {
      if (!!this.saCredentials.clientId && !!this.saCredentials.clientSecret) {
        return true
      }
      return false
    }
  }
}
</script>

<style scoped>

</style>
