<template>
  <step v-bind="$props">
    <template v-slot:name>Download method (DT, Report lld)</template>
    <template v-slot:content>
      <v-radio-group row
                     :value="downloadMethod.type"
                     @change="$emit('change', changeValue('type', $event))"
      >
        <v-radio label="DataTransfert" value="datatransfert"></v-radio>
        <v-radio label="Report LLD" value="report_lld"></v-radio>
      </v-radio-group>

      <v-text-field v-if="downloadMethod.type==='datatransfert'"
                    label="Bucket Name" small
                    :value="downloadMethod.bucketName"
                    @change="$emit('change', changeValue('bucketName', $event))"
      ></v-text-field>
      <v-text-field
        v-if="downloadMethod.type==='datatransfert' && downloadMethod.bucketName.startsWith('dcdt_-dcm_account')"
        label="bucket prefix (can be empty)" small
        :value="downloadMethod.prefixName"
        @change="$emit('change', changeValue('prefixName', $event))"
      ></v-text-field>
      <v-btn color="primary" @click="$emit('save', downloadMethod)" :disabled="!isValid">Save</v-btn>
    </template>
  </step>
</template>

<script>
import step from '../step'
import { stepProps } from '../stepProps'

export default {
  name: 'stepDbmDownloadMethod',
  components: { step },
  mixins: [stepProps],
  model: {
    prop: 'downloadMethod',
    event: 'change'
  },
  props: {
    downloadMethod: Object
  },
  methods: {
    changeValue: function (key, event) {
      this.downloadMethod[key] = event
      return this.downloadMethod
    }
  },
  computed: {
    isValid: function () {
      console.log(this.downloadMethod)
      console.log(this.downloadMethod.type)
      if (this.downloadMethod.type === 'report_lld' ||
        (this.downloadMethod.type === 'datatransfert' && !!this.downloadMethod.bucketName)) {
        return true
      }
      return false
    }
  },
  watch: {
    'downloadMethod.bucketName': function (newValue, oldValue) {
      this.downloadMethod.prefixName = ''
    }
  }
}
</script>

<style scoped>

</style>
