<template>
  <step v-bind="$props">
    <template v-slot:name>Authorize service account to usurpate dbm@scibids.com</template>
    <template v-slot:content>
      <v-progress-circular
        indeterminate
        color="primary" v-if="loading"
      ></v-progress-circular>
      <div v-else>
        <p>
          Open <a v-bind:href="saAuthUrl"  class="text-wrap"
                  target="_blank" rel="noopener noreferrer">{{ saAuthUrl }}</a> and connect with dbm@scibids.com
          account
        </p>
        <p>Waiting...</p>
      </div>
    </template>
  </step>
</template>

<script>
import step from '../step'
import { stepProps } from '../stepProps'

export default {
  name: 'stepDbmAuthorizeServiceAccount',
  components: { step },
  mixins: [stepProps],
  props: {
    saAuthUrl: String,
    loading: Boolean
  }
}
</script>

<style scoped>

</style>
