import { stepState } from '../components/KeystoneComponents/projectConfig/step/stepState'

export const projectConfig = {
  props: ['client_id'],
  data: function () {
    return {
      running: null, // Indique l'id de la step en train de tourner
      loading: { // Permet d'afficher l'icone de chargement à différent endroit
        statusConfig: false
      },
      statusConfig: { // Indique le status de la configuration du projet. Load grace à un appel api
      },
      stepSettings: [// définie les différentes étapes
      ],
      errors: {// Indique s'il y a une erreur à une étape. Récupéré depuis l'api
      },
      allowToUpdateConfig: false
    }
  },
  methods: {
    stepState: function (stepId) {
      if (this.statusConfig[this.getStepName(stepId)]) {
        return stepState.DONE
      } else if (this.running === stepId) {
        return stepState.RUNNING
      }
      return stepState.NOT_COMPLETED
    },
    stepAttributes1: function (stepId) {
      return {
        error: this.errors[this.getStepName(stepId)],
        'step-number': stepId,
        state: this.stepState(stepId)
      }
    },
    stepperAttributes: function () {
      return {
        loading: this.loading.statusConfig,
        error: this.errors['notLoaded'],
        'all-step-completed': this.allStepCompleted,
        'can-do-step': this.canDoStep,
        'allow-to-update-config': this.allowToUpdateConfig,
        currentStep: this.currentStep
      }
    },
    stepperEvent: function () {
      return {
        start: function ($event) { this.doStep(this.currentStep) }.bind(this),
        change: function ($event) { this.currentStep = $event }.bind(this)
      }
    },
    // helper qui permet de sétter les paramètres de chaque étape
    stepAttributes: function (stepId) {
      return {
        'complete': this.isDone(stepId) || this.running === stepId,
        'complete-icon': this.getIconState(stepId),
        'editable': this.stepSettings[stepId - 1].editable && this.isDone(stepId),
        'rules': [() => !this.errors[this.getStepName(stepId)]],
        step: stepId
      }
    },
    // Permet de personaliser l'icone d'une step.
    // On change dans le cas où une étape est en running
    getIconState: function (stepId) {
      if (this.running === stepId) {
        return '$vuetify.icons.loading'
      }
      return '$vuetify.icons.complete'
    },
    // Retourne le nom d'une étape grâce à son id
    getStepName: function (stepId) {
      return this.stepSettings[stepId - 1].stepName
    },
    // Retourne l'id d'une étape. L'id correspond à l'index dans le tableau stepSettings
    getStepId: function (stepName) {
      return this.stepSettings.findIndex((element) => element.stepName === stepName) + 1
    },
    // Vérifie si une étape est terminée
    isDone: function (stepId) {
      return this.statusConfig[this.getStepName(stepId)]
    },
    // Charge l'état courant de la configuration du projet
    loadStatusConfig: async function () {
      let response = await this.$apiCaller.getProjectConfigStatus(this.dsp, this.client_id)
      if (response.status === 200) {
        this.statusConfig = response.data
        this.allowToUpdateConfig = response.allow_to_config
        return null
      } else if (response.response && response.response.status === 403) {
        return 'You need additionnal user rights to access this feature'
      } else {
        return 'There are unknown error, please ask to dev team'
      }
    },
    // à utiliser pour ajouter des données au moment de valider une étape
    getData: function (stepId) {
      return {}
    },
    // Fait un appel api pour exécuter une tache
    // Si une tache est réalisée sans erreur, elle exécute l'étape suivante si c'est une étape qui ne demande pas d'information complémentaire
    doStep: async function (stepId, stepData = null) {
      let step = this.stepSettings[stepId - 1]
      let stepName = step.stepName
      this.running = stepId
      this.errors[stepName] = ''
      if (stepData === null) {
        stepData = this.getData(stepId)
      }
      let response = await this.$apiCaller.putProjectConfigStatus(this.dsp, this.client_id, stepName, stepData)
      this.running = null
      if (response.status === 200 && !response.errors) {
        this.statusConfig[stepName] = true
        this.errors[stepName] = ''

        // Si l'étape suivante peut être exécuté sans information complémentaire
        let nextStep = this.stepSettings[this.currentStep - 1]
        if (!this.allStepCompleted && !nextStep.informationRequired) {
          await this.doStep(this.currentStep)
        }
      } else {
        if (response.response && response.response.data) {
          this.errors[stepName] = response.response.data.errors
        }
      }
    },
    close: function () {
      this.$emit('close')
    }
  },
  computed: {
    allStepCompleted: function () {
      return this.currentStep === 100
    },
    currentStep: {
      get: function () {
        let index = this.stepSettings.findIndex(element => !this.statusConfig[element.stepName])
        if (index === -1) {
          // all complete
          return 100
        }
        return index + 1
      },
      set: function (value) {}
    },
    // Indique si une étape peut être exécuté sans information complémentaire
    canDoStep: function () {
      let step = this.stepSettings[this.currentStep - 1]
      return !this.allStepCompleted && !step.informationRequired && !this.running
    }
  },
  watch: {
  },
  beforeMount: function () {
    this.loading.statusConfig = true
    this.loadStatusConfig().then((value) => {
      this.loading.statusConfig = false
      this.errors['notLoaded'] = value
    })
  }
}
