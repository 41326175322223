<template>
  <div>
    <v-stepper-step
      v-bind:complete="complete"
      v-bind:complete-icon="completeIcon"
      v-bind:editable="isEditable"
      v-bind:rules="[() => !error]"
      v-bind:step="stepNumber"
    >
      <slot name="name"></slot>
      <small><slot name="description"></slot></small>

      <slot name="error">
        <small v-if="error">Error: {{error}}</small>
      </slot>
    </v-stepper-step>
    <v-stepper-content v-bind:step="stepNumber" class="small-stepper-content">
      <slot name="content"></slot>
    </v-stepper-content>
  </div>
</template>

<script>
import { stepState } from './stepState'

export default {
  name: 'step',
  props: {
    error: String,
    stepNumber: Number,
    state: String,
    editable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    complete: function () {
      return [stepState.DONE, stepState.RUNNING].includes(this.state)
    },
    completeIcon: function () {
      return this.state === stepState.RUNNING ? '$vuetify.icons.loading' : '$vuetify.icons.complete'
    },
    isEditable: function () {
      return this.editable && this.state === stepState.DONE
    }
  }
}
</script>

<style scoped>

</style>
