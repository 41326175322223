<template>
  <v-container className="keystone-tool-container">
    <stepper
      v-bind="stepperAttributes()"
      v-on="stepperEvent()"
    >
      <step-project-creation v-bind="stepAttributes1(1)"></step-project-creation>
      <step-creation-service-account v-bind="stepAttributes1(2)"></step-creation-service-account>
      <step-bucket-shared v-bind="stepAttributes1(3)"></step-bucket-shared>
      <step-bucket-spillway v-bind="stepAttributes1(4)"></step-bucket-spillway>
      <step-configure-bigquery v-bind="stepAttributes1(5)"></step-configure-bigquery>

      <step-dbm-activate-dbm-api v-bind="stepAttributes1(6)"></step-dbm-activate-dbm-api>
      <step-dbm-activate-dv-api v-bind="stepAttributes1(7)"></step-dbm-activate-dv-api>

      <step-dbm-create-scibids-app v-bind="stepAttributes1(8)"></step-dbm-create-scibids-app>

      <step-dbm-create-service-account-api
        v-bind="stepAttributes1(9)"
        v-model="saCredentials"
        :loading="loading.serviceAccountCredentials"
        :saSettingsCreation="saSettingsCreation"
        @save="saveSACredentials"
      ></step-dbm-create-service-account-api>

      <step-dbm-authorize-service-account
        v-bind="stepAttributes1(10)"
        :saAuthUrl="saAuthUrl"
        :loading="loading.serviceAccountToken"
      ></step-dbm-authorize-service-account>

      <step-dbm-download-method
        v-bind="stepAttributes1(11)"
        v-model="downloadMethod"
        @save="saveDownloadMethod"
      ></step-dbm-download-method>

      <step-first-init v-bind="stepAttributes1(12)"
                       @launch="launchFirstInit"
      ></step-first-init>

    </stepper>
  </v-container>
</template>

<script>

import { projectConfig } from '../../../mixins/projectConfig'
import StepCreationServiceAccount from './step/creation_service_account'
import StepConfigureBigquery from './step/configure_bigquery'
import StepBucketSpillway from './step/bucket_spillway'
import StepFirstInit from './step/first_init'
import StepBucketShared from './step/bucket_shared'
import Stepper from './step/stepper'
import stepProjectCreation from './step/project_creation'
import StepDbmCreateServiceAccountApi from './step/dbm/create_service_account_api'
import StepDbmAuthorizeServiceAccount from './step/dbm/authorize_service_account'
import StepDbmActivateDbmApi from './step/dbm/activate_dbm_api'
import StepDbmActivateDvApi from './step/dbm/activate_dv_api'
import StepDbmDownloadMethod from './step/dbm/download_method'
import StepDbmCreateScibidsApp from './step/dbm/create_scibids_app'

export default {
  name: 'projectConfigDBM',
  mixins: [projectConfig],
  components: {
    StepDbmCreateScibidsApp,
    StepDbmDownloadMethod,
    StepDbmActivateDvApi,
    StepDbmActivateDbmApi,
    StepDbmAuthorizeServiceAccount,
    StepDbmCreateServiceAccountApi,
    StepCreationServiceAccount,
    StepConfigureBigquery,
    StepBucketSpillway,
    StepFirstInit,
    StepBucketShared,
    Stepper,
    stepProjectCreation
  },
  data: function () {
    return {
      dsp: 'dbm',
      loading: {
        serviceAccountCredentials: false,
        serviceAccountToken: false
      },
      saCredentials: {
        clientId: '',
        clientSecret: ''
      },
      saSettingsCreation: {
        url_creation: '',
        url_callback: '',
        account_name: ''
      },
      saAuthUrl: '',
      downloadMethod: {
        type: '',
        bucketName: '',
        prefixName: ''
      },
      stepSettings: [
        { stepName: 'project_creation' },
        { stepName: 'creation_service_account' },
        { stepName: 'bucket_creation_shared_dbm' },
        { stepName: 'bucket_creation_spillway_dbm' },
        { stepName: 'config_bigquery' },
        { stepName: 'activate_dbm_api' },
        { stepName: 'activate_dv_api' },
        { stepName: 'config_scibids_app' },
        { stepName: 'service_account_credentials', informationRequired: true },
        { stepName: 'service_account_token', informationRequired: true },
        { stepName: 'download_method', informationRequired: true },
        { stepName: 'first_init', informationRequired: true }
      ],
      timeout: {
        reloadUntil: null
      }
    }
  },
  methods: {
    saveSACredentials: function () {
      let stepData = {
        sa_client_id: this.saCredentials.clientId,
        sa_client_secret: this.saCredentials.clientSecret
      }
      this.doStep(this.getStepId('service_account_credentials'), stepData)
    },
    saveDownloadMethod: function () {
      let stepData = {
        type: this.downloadMethod.type,
        bucket_name: this.downloadMethod.bucketName,
        prefix_name: this.downloadMethod.prefixName
      }
      this.doStep(this.getStepId('download_method'), stepData)
    },
    launchFirstInit: function () {
      this.doStep(this.getStepId('first_init'))
    },
    reloadUntilStepComplete: async function (stepId, stepIdToWatch) {
      await this.loadStatusConfig()
      if (this.currentStep === stepIdToWatch) {
        let that = this
        if (this.timeout.reloadUntil) {
          clearTimeout(this.timeout.reloadUntil)
          this.timeout.reloadUntil = null
        }
        this.timeout.reloadUntil = setTimeout(function () {
          that.reloadUntilStepComplete(stepId, stepIdToWatch)
        }, 6000)
      } else {
        await this.doStep(this.currentStep)
      }
    }
  },
  watch: {
    currentStep: {
      async handler (newValue, oldValue) {
        if (newValue === this.getStepId('service_account_credentials')) {
          this.loading.serviceAccountCredentials = true
          let response = await this.$apiCaller.getServiceAccountCreation(this.dsp, this.client_id)
          this.loading.serviceAccountCredentials = false
          if (response.status === 200) {
            this.errors['service_account_credentials'] = ''
            this.saSettingsCreation = response.data
          } else {
            this.errors['service_account_credentials'] = response.errors
          }
        } else if (newValue === this.getStepId('service_account_token')) {
          this.loading.serviceAccountToken = true
          let response = await this.$apiCaller.generateUrlServiceAccount(this.client_id)
          this.loading.serviceAccountToken = false
          if (response.status === 200) {
            this.errors['service_account_token'] = ''
            this.saAuthUrl = response.data
            await this.reloadUntilStepComplete(newValue, this.getStepId('service_account_token'))
          } else {
            this.errors['service_account_token'] = response.errors
          }
        }
      }
    }
  }
}
</script>

<style scoped>
.small-stepper-content.v-stepper__content {
  padding-top: 5px;
  padding-bottom: 10px;
}

.text-wrap {
  overflow-wrap: break-word;
}

</style>
