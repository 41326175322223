export const stepProps = {
  props: {
    error: String,
    stepNumber: Number,
    state: String,
    editable: {
      type: Boolean,
      default: false
    }
  }
}
